export const Bio = {
  name: "Em Diya",
  roles: [
    "Mobile App Developer",
    "Software Developer",
    "Full Stack Developer",
  ],
  description:
    "Hello, I'm Em Diya, a Software Developer with a Bachelor’s in Computer Science in Cambodia. I specialize in Mobile and Full Stack Development, creating high-quality, user-centered software. I am passionate about expanding my skills and embracing new challenges.",
  github: "https://github.com/emdiya",
  resume:
    "https://www.linkedin.com/in/emdiya/",
  service: "https://www.linkedin.com/in/emdiya/",
  linkedin: "https://www.linkedin.com/in/emdiya/",
  twitter: "https://twitter.com/diyacambo",
  insta: "https://www.instagram.com/diii.dev/",
  facebook: "https://www.facebook.com/diyacreator/",
  telegram: "https://t.me/diyasoftware"
};


export const service = [
  {
    title: "Mobile App Development",
    service: [
      {
        name: "We craft high-performance mobile applications for both Android and iOS platforms using the Flutter framework. Our focus is on creating seamless, user-friendly apps that offer a great experience for users.",
        image:
          "https://www.svgrepo.com/show/530452/mobile-app.svg",
      },
    ],
  },
  {
    title: "Web Development",
    service: [
      {
        name: "We design and develop responsive web applications that are both visually appealing and highly functional. Utilizing the latest technologies, we ensure that your web app stands out and performs excellently across all devices.",
        image: "https://www.svgrepo.com/show/530444/availability.svg",
      },
    ],
  },
  {
    title: "Backend Development",
    service: [
      {
        name: "Our expertise in backend development ensures that your application has a robust and efficient server-side architecture. We develop secure and scalable backend systems that facilitate seamless data flow and interactions.",
        image:
          "https://www.svgrepo.com/show/530439/api-interface.svg",
      },
    ],
  },
  {
    title: "UX/UI Design",
    service: [
      {
        name: "We believe that a great user experience is key to the success of any application. Our UX/UI design services focus on creating intuitive and visually appealing interfaces that enhance user satisfaction and engagement.",
        image: "https://www.svgrepo.com/show/530442/port-detection.svg",
      },

    ],
  },
];


export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "Flutter",
        image:
          "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
      },
      {
        name: "Next Js",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "PHP",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg",
      },
      {
        name: "Tailwind CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg",
      },
      {
        name: "Redux",
        image:
          "https://www.svgrepo.com/show/303557/redux-logo.svg",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Nest Js",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/NestJS.svg/1200px-NestJS.svg.png"
      },
      {
        name: "Spring Boot",
        image:
          "https://cdn.worldvectorlogo.com/logos/spring-3.svg"
      },
      {
        name: "Laravel",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg"
      },

      {
        name: "GraphQL",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/1/17/GraphQL_Logo.svg"
      },
      {
        name: "MySQL",
        image:
          "https://www.svgrepo.com/show/303251/mysql-logo.svg"
      },
      {
        name: "PostgreSQL",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg"
      },

    ],
  },
  {
    title: "Mobile",
    skills: [
      {
        name: "Flutter",
        image:
          "https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "App Store Connect",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/6/67/App_Store_%28iOS%29.svg",
      },
      {
        name: "Google Play Console",
        image:
          "https://freelogopng.com/images/all_img/1664285914google-play-logo-png.png",
      },
      {
        name: "Testflight",
        image:
          "https://cdn.jim-nielsen.com/macos/512/testflight-2023-05-19.png?rf=1024",
      },
    ],
  },

  {
    title: "Cloud & DevOps",
    skills: [
      {
        name: "Jenkins",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/e/e9/Jenkins_logo.svg",
      },

      {
        name: "Docker",
        image:
          "https://svgl.app/library/docker.svg",
      },
      {
        name: "Kubernetes",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg",
      },
      {
        name: "Google Cloud",
        image: "https://static-00.iconduck.com/assets.00/google-cloud-icon-2048x1646-7admxejz.png",
      },
      {
        name: "AWS",
        image: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      },
      {
        name: "Hostgator",
        image: "https://www.svgrepo.com/show/353881/hostgator-icon.svg",
      },


    ],
  },
  {
    title: "UX/UI",
    skills: [
      {
        name: "Figma",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg",
      },
      {
        name: "Illustrator",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg",
      },

      {
        name: "Photoshop",
        image: "https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg",
      },
      {
        name: "Adobe XD",
        image: "https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg",
      },

    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "Microservice",
        image:
          "https://cdn-icons-png.freepik.com/512/10061/10061283.png",
      },
      {
        name: "Kafka",
        image: "https://openwhisk.apache.org/images/icons/icon-kafka-white-trans.png",
      },
      {
        name: "Redis",
        image:
          "https://svgl.app/library/redis.svg",
      },
      {
        name: "Git",
        image:
          "http://upload.wikimedia.org/wikipedia/commons/3/3f/Git_icon.svg",
      },

      {
        name: "Vercel",
        image:
          "https://registry.npmmirror.com/@lobehub/icons-static-png/latest/files/dark/vercel.png",
      },
      {
        name: "Railway",
        image: "https://svgl.app/library/railway_dark.svg",
      },
      {
        name: "Render",
        image: "https://cdn.sanity.io/images/hvk0tap5/production/0ea63c1b6854bd803489557afb4ea54b85239418-128x128.png?fit=max&auto=format",
      },
    ],
  },
];


export const languages = [
  {
    skills: [
      {
        name: "Dart",
        image:
          "https://static.cdnlogo.com/logos/d/66/dart.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg",
      },
      {
        name: "Typescript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
      },
      {
        name: "Java",
        image:
          "https://www.svgrepo.com/show/184143/java.svg",
      },

      {
        name: "PHP",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/3/31/Webysther_20160423_-_Elephpant.svg",
      },


    ],
  },
];

export const experiences = [

  {
    id: 0,
    img: "https://niph.org.kh/niph/uploads/niph-logo.png",
    role: "Software Developer",
    company: "National Institute of Public Health",
    date: "Nov 2024 - Present",
    desc: "Developing robust backend APIs using Laravel for seamless data flow and efficient server-side functionality. Crafting responsive and visually appealing frontends for mobile and web applications with Flutter, API, and Material UI. Designing intuitive UX/UI to enhance user satisfaction.",
    skills: [
      "Flutter Development",
      "Larvel Development",
      "Figma",
      "Docker",
      "Kubernetes",
      "Firebase",
      "Google Cloud",
      "Linux Server"
    ],
  },

  {
    id: 1,
    img: "https://i.ibb.co/MkBH4rL/Smart-Logo-Final-2-01.png",
    role: "Officer Flutter Developer",
    company: "SOPHEA MOLY AGRO-RETAILS & TRADING CO., LTD",
    date: "Nov 2023 - Nov 2024",
    desc: "Working on the frontend of the mobile and web application using Flutter, API, and Material UI.",
    skills: [
      "Flutter",
      "Dart",
      "Getx",
      "Gouter",
      "Postman",
      "API",
      "Firebase",
      "Figma",
      "Git",
      "Google Play Console",
      "Testflight"
    ],
    doc: "https://i.ibb.co/sbtD7vs/1a.png",
  },
  {
    id: 2,
    img: "https://iili.io/JXXJWbI.png",
    role: "Junior Flutter Developer",
    company: "Z1 Company",
    date: "Nov 2021 - Jul 2023",
    desc: "Working on the mobile application using Flutter, Dart",
    skills: [
      "Flutter",
      "Dart",
      "Getx",
      "Gouter",
      "Postman",
      "API",
      "Firebase",
      "Figma",
      "Git",
    ],
    doc: "https://iili.io/JX5TlrQ.png",
  },
  {
    id: 4,
    img: "https://iili.io/JWNVdcG.png",
    role: "Full Stack Developer Freelancer",
    company: "KD Digital",
    date: "May 2021 - Present",
    desc: "I have started my own freelance business specializing in Mobile App Development, Web Development, Backend Development, and UX/UI Design.",
    skills: [
      "Flutter Development",
      "Front-End Development",
      "Back-End Development",
      "UX/UI Designer"
    ],
  },

];

export const education = [
  {
    id: 0,
    img: "https://upload.wikimedia.org/wikipedia/en/a/a2/RUPP_logo.PNG",
    school: "Royal University of Phnom Penh",
    date: "Mar 2021 - May 2024",
    grade: "90%",
    desc: "I hold a Bachelor's degree in Computer Science and Engineering from the Royal University of Phnom Penh, Cambodia. During my four years of study, I undertook comprehensive courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I excelled in these subjects, which equipped me with a strong foundation in both theoretical and practical aspects of computer science.",
    degree: "Bachelor of Faculty of science , Computer Science and Engineering",
  },

  {
    id: 1,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Emblem_of_the_Ministry_of_Education%2C_Youth_and_Sport_%28Cambodia%29.svg/888px-Emblem_of_the_Ministry_of_Education%2C_Youth_and_Sport_%28Cambodia%29.svg.png?20110624073833",
    school: "Kampong Trabek High School",
    date: "June 2017 - Oct 2020",
    grade: "90.0%",
    desc: "General Knwoledge",
    degree: "Bac II",
  },
];


export const training = [

  {
    id: 3,
    img: "https://cdn.worldvectorlogo.com/logos/spring-3.svg",
    school: "Spring Bootcamp",
    date: "August 2024 - Nov 2024",
    desc: "In this course, I learned the fundamentals of Spring Boot, focusing on building RESTful APIs, implementing Spring Security for user authentication, and utilizing Spring JPA with ORM for efficient data management. I also explored microservices architecture and encryption techniques for data protection.",
    degree: "Short Course",
  },
  {
    id: 2,
    img: "https://upload.wikimedia.org/wikipedia/commons/a/a8/NestJS.svg",
    school: "NestJS Masterclass",
    date: "July 2023 - Dec 2023",
    desc: "In this course, I learned the basics of Nest.js, gaining expertise in building RESTful APIs. I explored Nest Middleware and Guards to enhance functionality and security, implemented Nest Security Authentication for secure user access, and delved into encryption and hashing techniques to protect sensitive data effectively. Additionally, I learned how to use Docker for containerization, streamlining the deployment and management of applications.",
    degree: "Short Course",
  },

  {
    id: 1,
    img: "https://cdn.iconscout.com/icon/free/png-256/free-flutter-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-company-vol-3-pack-logos-icons-3030139.png",
    school: "Flutter Bootcamp",
    date: "June 2021 - Oct 2021",
    grade: "90.0%",
    desc: "In this course, I began by learning the fundamentals of Dart and Flutter, establishing a solid foundation in mobile app development. I explored UI design, creating visually appealing interfaces, and delved into API integration for dynamic data handling. Additionally, I learned to implement Firebase Authentication for secure user login and utilized Firebase Firestore to seamlessly store and manage app data.",
    degree: "Short Course",
  },


  {
    id: 0,
    img: "https://iili.io/JXXH092.png",
    school: "ETEC Center - មជ្ឈមណ្ឌលបណ្តុះជំនាញ I.T",
    date: "Dec 2020 - Feb 2021",
    grade: "100%",
    desc: "I completed my short course C/C++/OOP and have a grade 100%",
    degree: "Short Course",
  },

];

export const projects = [
  {
    id: 0,
    title: "Json to Freeze Model Web",
    date: "Feb 2024 - Feb 2024",
    description:
      "Json to Freeze Model. Make a tool online that can pass json value and past value to Freeze Model in Flutter",
    image:
      "https://iili.io/JWNvQja.png",
    tags: [
      "Next JS",
      "Typescript",
      "Tailwindcss",
      "Javascript",
      "Vercel",
    ],
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    category: "web app",
    github: "https://github.com/emdiya/json_to_freezed_web",
    webapp: "https://jsontofreezed.vercel.app/",
  },
  {
    id: 1,
    title: "Recourse Center Hub Web",
    date: "Jan 2024 - Mar 2024",
    description:
      "Online Resource Center Hub (ORH) is the platform Is a gathering place for documented sources of both domestic and foreign financial sectors.",
    image:
      "https://iili.io/JWNSKR1.png",
    tags: ["Next Js", "TypeScript", "Redux", "Taiwindcss", "Postgresql", "Prisma", "Postman", "AWS", "Vercel"],
    category: "web app",
    github: "#",
    webapp: "https://orh.cma-academyforbfi.org/",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
      {
        name: "Um Kithya",
        img: "https://media.licdn.com/dms/image/D4D03AQG5CYZTQW-qHw/profile-displayphoto-shrink_800_800/0/1676521398119?e=2147483647&v=beta&t=nWVyvilN2TJ1gc5GHf3K_BHWi5NVGEMl0KNB2IbTnGY",
        linkedin: "https://www.linkedin.com/in/um-kithya/",
        github: "https://github.com/umkithya",
      },
    ],
  },
  {
    id: 2,
    title: "Prohose Web",
    date: "NOv 2023 - Present",
    description:
      "Website Prohose has enhanced features for online shopping, fast delivery, and detailed product descriptions.",
    image:
      "https://i.ibb.co/zJMbwyG/2a.png",
    tags: [
      "Flutter",
      "Flutter Web",
      "Dart",
      "Postman",
      "API",
      "Firebase",
      "Figma",
      "Git",
    ],
    category: "web app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
      {
        name: "Um Kithya",
        img: "https://media.licdn.com/dms/image/D4D03AQG5CYZTQW-qHw/profile-displayphoto-shrink_800_800/0/1676521398119?e=2147483647&v=beta&t=nWVyvilN2TJ1gc5GHf3K_BHWi5NVGEMl0KNB2IbTnGY",
        linkedin: "https://www.linkedin.com/in/um-kithya/",
        github: "https://github.com/umkithya",
      },
    ],
    github: "#",
    webapp: "#",
  },

  {
    id: 3,
    title: "Prohose Mobile",
    date: "NOv 2023 - Present",
    description:
      "App Prohose has enhanced features for online shopping, secure payments, fast delivery, and detailed product descriptions.",
    image:
      "https://i.ibb.co/hs3fGQ9/3a.png",
    tags: [
      "Flutter",
      "Dart",
      "Dio",
      "GetX",
      "Go Router",
      "Postman",
      "API",
      "Firebase",
      "Figma",
      "Git",
    ],
    category: "mobile app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
      {
        name: "Um Kithya",
        img: "https://media.licdn.com/dms/image/D4D03AQG5CYZTQW-qHw/profile-displayphoto-shrink_800_800/0/1676521398119?e=2147483647&v=beta&t=nWVyvilN2TJ1gc5GHf3K_BHWi5NVGEMl0KNB2IbTnGY",
        linkedin: "https://www.linkedin.com/in/um-kithya/",
        github: "https://github.com/umkithya",
      },
    ],
    github: "#",
    webapp: "https://iili.io/JWNrtrx.png",
  },
  {
    id: 4,
    title: "UT HR Mobile",
    date: "Nov 2023 - Present",
    description:
      "UT HR App Feature tracking of their employees or Staff Attendance.",
    image:
      "https://i.ibb.co/sbtD7vs/1a.png",
    tags: ["Flutter", "Dart", "Getx", "Gouter", "Dio", "Figma", "Firebase", "Google API", "Postman", "Git", "Google Play Console", "Testflight"],
    category: "mobile app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
      {
        name: "Um Kithya",
        img: "https://media.licdn.com/dms/image/D4D03AQG5CYZTQW-qHw/profile-displayphoto-shrink_800_800/0/1676521398119?e=2147483647&v=beta&t=nWVyvilN2TJ1gc5GHf3K_BHWi5NVGEMl0KNB2IbTnGY",
        linkedin: "https://www.linkedin.com/in/um-kithya/",
        github: "https://github.com/umkithya",
      },
    ],
    github: "",
    webapp: "https://play.google.com/store/apps/details?id=com.smagroretails.uthr.pre&hl=en",
  },
  {
    id: 5,
    title: "KD Drama Mobile",
    date: "progressing",
    description:
      "Designed and developed the KD Drama, Online Movie in App",
    image:
      "https://i.ibb.co/rtP7NbN/KD-Drama.png",
    tags: ["Flutter", "Dart", "Bloc", "Go Router", "Figma", "Firebase", "Google API", "Postman"],
    category: "mobile app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },

  {
    id: 6,
    title: "KD Drama Web",
    date: "progressing",
    description:
      "Designed and developed the KD Drama, Online Streaming Movie in Web",
    image:
      "https://iili.io/JWO6dmB.png",
    tags: ["Next JS", "Nest JS", "PosgresSQL", "Firebase", "Vercel", "AWS", "Postman"],
    category: "web app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },

    ],
    github: "",
    webapp: "",
  },
  {
    id: 7,
    title: "KD Drama Admin Panel",
    date: "progressing",
    description:
      "Designed and developed the KD Drama, Online Streaming Movie in Web",
    image:
      "https://i.ibb.co/hR3gJMd/tg-image-1606374534.jpg",
    tags: ["Next JS", "Redux", "PosgresSQL", "Firebase", "Vercel", "AWS", "Postman"],
    category: "web app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },

  {
    id: 8,
    title: "KD Drama UX/UI",
    date: "Designed the KD Drama app and web interface using Figma, ensuring a user-friendly and visually appealing experience.",
    description:
      "Designed using Figma",
    image:
      "https://i.ibb.co/rtP7NbN/KD-Drama.png",
    tags: ["Figma"],
    category: "ux ui",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },
  {
    id: 9,
    title: "Recourse Center Hub UX/UI",
    date: "Jan 2024 - Mar 2024",
    description:
      "Designed and developed the Resource Center Hub E-Library using Figma, creating a responsive and user-friendly interface for both web clients and the admin dashboard. Ensured a visually appealing and intuitive experience for all users.",
    image:
      "https://iili.io/JWNSKR1.png",
    tags: ["Figma"],
    category: "ux ui",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },
  {
    id: 10,
    title: "Nike E-Commerce",
    date: "Jul 2023 - Oct 2023",
    description:
      "Developed Backend for Nike E-Commmerce using Node JS",
    image:
      "https://iili.io/JWrKmen.png",
    tags: ["Node Js", "PosgresSql", "Sequelize"],
    category: "backend",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },
  {
    id: 11,
    title: "DY CMD Download",
    date: "Jul 2023 - Oct 2023",
    description:
      "DY CMD Download is an online tool that allows users to download videos from any platform, including Facebook, TikTok, and YouTube.DY CMD Download is online tool for download video from Facebook, Tiktok, Youtube",
    image:
      "https://iili.io/JXWhMU7.png",
    tags: ["CMD", "Batch Script", "ffmpeg"],
    category: "other",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "https://t.me/diyasoftware/241",
  },
  {
    id: 12,
    title: "Z1 App",
    date: "Nov 2022 - Jul 2023",
    description:
      "Z1 is the first app in Cambodia to provide a fully integrated and convenient solution for real estate-related decision-making. The app enables users to easily browse through various options of properties, including apartments, villas, commercial properties, and offices, and contact the respective property owners directly. ",
    image:
      "https://iili.io/JXWDFCx.png",
    tags: ["Flutter", "Dart", "Getx", "Gouter", "Postman", "API", "Firebase", "Figma", "Git",],
    category: "mobile app",
    github: "",
    webapp: "",
  },
  {
    id: 13,
    title: "My Property & AgencyPost",
    date: "Nov 2022 - Jul 2023",
    description:
      "My Property & AgencyPost is Mini App for Z1 Company",
    image:
      "https://iili.io/JXWsyx4.png",
    tags: ["Flutter", "Dart", "Getx", "Gouter", "Postman", "API", "Firebase", "Figma", "Git",],
    category: "mobile app",
    github: "",
    webapp: "",
  },
  {
    id: 14,
    title: "Indication Plus",
    date: "Nov 2022 - Jul 2023",
    description:
      "Indication Plus is Mini App for Z1 Company",
    image:
      "https://iili.io/JXWDPcB.png",
    tags: ["Flutter", "Dart", "Getx", "Gouter", "Postman", "API", "Firebase", "Figma", "Git",],
    category: "mobile app",
    github: "",
    webapp: "",
  },
  {
    id: 15,
    title: "CiC Mobile",
    date: "Nov 2022 - Jul 2023",
    description:
      "Cic project forCambodian Investors Corporation Plc.",
    image:
      "https://iili.io/JXWmtEv.png",
    tags: ["Flutter", "Dart", "Getx", "Gouter", "Postman", "API", "Firebase", "Figma", "Git",],
    category: "mobile app",
    github: "",
    webapp: "https://play.google.com/store/apps/details?id=com.cambodianinvestorscorporation&pcampaignid=web_share",
  },
  {
    id: 16,
    title: "MC Dragon",
    date: "Aug 2023 - Oct 2023",
    description:
      "MC Dragon is The best Social Media for Pet Lover in Cambodia",
    image:
      "https://iili.io/JXWyqog.png",
    tags: ["Flutter", "Dart", "Provider", "Postman", "API", "Firebase", "Figma", "Git",],
    category: "mobile app",
    github: "",
    webapp: "https://play.google.com/store/apps/details?id=com.asurraa.mcdragon",
  },
  {
    id: 17,
    title: "DY CMD Live",
    date: "Jul 2023 - Oct 2023",
    description:
      "DY CMD Live is online tool for Live and Streaming on Facebook",
    image:
      "https://iili.io/JXWhMU7.png",
    tags: ["CMD", "Batch Script", "ffmpeg"],
    category: "other",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "https://t.me/diyasoftware/241",
  },

  {
    id: 18,
    title: "KD Drama Backend",
    date: "progressing",
    description:
      "Developed the KD Drama, Online Streamming Movie API",
    image:
      "https://i.ibb.co/rtP7NbN/KD-Drama.png",
    tags: ["Nest JS", "Prisma", "PosgresSQL", "Firebase", "Vercel", "AWS", "Postman", "Docker"],
    category: "backend",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "",
  },
  {
    id: 18,
    title: "KD Online Download",
    date: "progressing",
    description:
      "KD Download is an online tool that allows users to download videos from any platform, including Facebook, TikTok, and YouTube.",
    image:
      "https://i.ibb.co/HtwbvYT/image.png",
    tags: ["Next JS", "Prisma", "Web Scraping"],
    category: "web app",
    member: [
      {
        name: "Em Diya",
        img: "https://i.ibb.co/2sjcywk/photo-2024-06-05-13-53-35.jpg",
        linkedin: "https://www.linkedin.com/in/emdiya",
        github: "https://github.com/emdiya",
      },
    ],
    github: "",
    webapp: "https://kd-online-downlaoder.vercel.app/",
  },


];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
